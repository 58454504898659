import { FaCheck } from 'react-icons/fa6';

const AddToCartHeader = (): JSX.Element => {
  return (
    <div>
      <div className="flex flex-row ">
        <FaCheck className="text-green-600" size={28} />
        <p className="pl-3 text-xl font-black">Added to Cart</p>
      </div>
    </div>
  );
};

export default AddToCartHeader;
