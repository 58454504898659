'use client';
import { Rating } from '@mui/material';
import { CarSelectionContext } from '@/contexts/CarSelectionContext';
import { Suspense, useContext, useState } from 'react';
import CartSheet from '@/components/cart/CartSheet';
import { useStore } from 'zustand';
import { useCartContext } from '@/providers/CartProvider';
import { TPathParams, TQueryParams, getCompleteSelectionData } from '@/utils';
import FreeDetails from './FreeDetails';
import AddToCart from '@/components/cart/AddToCart';
import CircleColorSelector from './CircleColorSelector';
import ReviewsTextTrigger from './ReviewsTextTrigger';
import ProductTitle from './ProductTitle';
import PreorderSheet from '@/components/cart/PreorderSheet';
import PayPalIconCheckout from '@/components/checkout/icons/PayPalIcon';
import { handlePreorderAddToCartGoogleTag } from '@/hooks/useGoogleTagDataLayer';
import { useParams } from 'next/navigation';
import ViewItemGoogleTag from './ViewItemGoogleTag';

export function ProductContent({
  searchParams,
}: {
  searchParams: TQueryParams;
}) {
  const [addToCartOpen, setAddToCartOpen] = useState<boolean>(false);
  const store = useContext(CarSelectionContext);
  if (!store) throw new Error('Missing CarContext.Provider in the tree');
  const selectedProduct = useStore(store, (s) => s.selectedProduct);
  const modelData = useStore(store, (s) => s.modelData);
  const color = useStore(store, (s) => s.selectedColor);
  const { addToCart } = useCartContext();
  const params = useParams<TPathParams>();

  const {
    completeSelectionState: { isComplete },
  } = getCompleteSelectionData({
    data: modelData,
  });

  const cartProduct = modelData.find((p) => p.display_color === color);
  const defaultMSRP = 180; // Cheapest Car Cover Price
  const defaultPrice: number = defaultMSRP * 2;

  const defaultMSRPMinus5Cents = defaultMSRP - 0.05;

  const handleAddToCart = () => {
    if (!cartProduct) return;
    if (selectedProduct.preorder) {
      handlePreorderAddToCartGoogleTag(
        selectedProduct,
        params as TPathParams,
        'preorder_1_add-to-cart'
      );
    } else {
      addToCart({ ...cartProduct, quantity: 1 });
    }
    setAddToCartOpen(true);
  };

  const installmentPrice = isComplete
    ? (selectedProduct?.price ?? 0) / 8 - 0.01
    : defaultPrice / 8 - 0.01;

  const displayMsrp = isComplete
    ? selectedProduct?.msrp
    : defaultMSRPMinus5Cents;
  const displayPrice = isComplete ? selectedProduct?.price : defaultPrice;

  return (
    <>
      <div className="grid grid-cols-1 lg:mt-12">
        {isComplete && <ViewItemGoogleTag />}
        <div className="flex flex-col gap-0.5">
          <ProductTitle />
          {/* Rating(s) */}
          <div className="-ml-0.5 mt-1 flex items-end gap-1 lg:mt-2">
            <div className="flex gap-1 ">
              <Rating
                name="read-only"
                value={4.5}
                precision={0.1}
                readOnly
                sx={{
                  gap: '2px',
                  '& .MuiRating-iconFilled': {
                    color: '#BE1B1B',
                  },
                  '& .MuiRating-iconEmpty': {
                    color: '#BE1B1B',
                  },
                }}
              />
            </div>
            <ReviewsTextTrigger />
          </div>
        </div>
      </div>
      <section className="flex flex-col pt-[28px] ">
        <p className="mb-3 text-[16px] leading-[14px]">
          {isComplete ? '' : 'From'}
        </p>
        <div className=" flex  items-end gap-[9px]   text-center text-[28px] font-[900]  lg:text-[32px] lg:leading-[37.5px] ">
          <div className="leading-[20px]">${displayMsrp}</div>
          {displayPrice && (
            <div className="flex gap-1.5 pb-[1px] text-[22px] font-[400] leading-[14px] text-[#BE1B1B] lg:text-[22px] ">
              <span className=" text-[#BEBEBE] line-through">
                ${displayPrice}
              </span>
              <p>(-50%)</p>
            </div>
          )}
        </div>
        <div className="mt-1 flex items-center gap-0.5 ">
          <p className=" text-[16px] leading-[16px] text-[#767676] ">
            4 interest-free installments of{' '}
            <b className="font-[500] text-black">
              ${installmentPrice.toFixed(2)}
            </b>
          </p>
          <div className="ml-1 bg-gray-100 p-1">
            <PayPalIconCheckout />
          </div>
        </div>
      </section>
      <CircleColorSelector />
      <div className="lg:hidden">
        <AddToCart
          selectedProduct={selectedProduct}
          handleAddToCart={handleAddToCart}
          searchParams={searchParams}
          isSticky
        />
      </div>
      {/* <Separator className="mt-[36px]" /> */}
      <Suspense>
        <FreeDetails />
      </Suspense>
      <div className="lg:py-4"></div>
      <AddToCart
        selectedProduct={selectedProduct}
        handleAddToCart={handleAddToCart}
        searchParams={searchParams}
      />
      {selectedProduct.preorder ? (
        <PreorderSheet
          open={addToCartOpen}
          setOpen={setAddToCartOpen}
          selectedProduct={selectedProduct}
        />
      ) : (
        <CartSheet
          open={addToCartOpen}
          setOpen={setAddToCartOpen}
          selectedProduct={selectedProduct}
        />
      )}
    </>
  );
}
