import React from 'react';

const PayPalIcon = () => {
  return (
    <svg
      width="54"
      height="17"
      viewBox="0 0 54 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="bg-gray-100"
    >
      <g clipPath="url(#clip0_4046_55458)">
        <path
          d="M6.97245 1.41992H2.89112C2.61183 1.41992 2.37431 1.63629 2.33074 1.93029L0.680066 13.0902C0.647243 13.3104 0.807178 13.5089 1.01665 13.5089H2.96511C3.2444 13.5089 3.48192 13.2926 3.52549 12.9979L3.97068 9.98792C4.01365 9.69328 4.25176 9.47692 4.53045 9.47692H5.82247C8.51094 9.47692 10.0626 8.08965 10.4678 5.34056C10.6504 4.13783 10.4755 3.19283 9.94737 2.53101C9.36731 1.80429 8.33847 1.41992 6.97245 1.41992ZM7.44331 5.49583C7.22011 7.05747 6.10116 7.05747 5.01921 7.05747H4.40334L4.83541 4.14101C4.86107 3.96474 5.00429 3.83492 5.17139 3.83492H5.45366C6.19068 3.83492 6.88592 3.83492 7.24518 4.28292C7.45942 4.55019 7.52507 4.94729 7.44331 5.49583Z"
          fill="#253B80"
        />
        <path
          d="M19.1737 5.44551H17.2193C17.0528 5.44551 16.9089 5.57533 16.8833 5.7516L16.7968 6.33451L16.6601 6.12324C16.237 5.46842 15.2935 5.24951 14.3518 5.24951C12.192 5.24951 10.3474 6.99378 9.98815 9.4406C9.80136 10.6611 10.0669 11.8282 10.7162 12.6421C11.3118 13.3905 12.164 13.7023 13.1779 13.7023C14.9181 13.7023 15.8831 12.5091 15.8831 12.5091L15.796 13.0882C15.7631 13.3097 15.9231 13.5082 16.1313 13.5082H17.8918C18.1717 13.5082 18.408 13.2919 18.4522 12.9972L19.5085 5.86424C19.5419 5.64469 19.3826 5.44551 19.1737 5.44551ZM16.4494 9.50169C16.2608 10.6923 15.3746 11.4916 14.2443 11.4916C13.6768 11.4916 13.2233 11.2975 12.932 10.9297C12.6432 10.5644 12.5334 10.0445 12.6253 9.46542C12.8013 8.28497 13.7025 7.4596 14.8155 7.4596C15.3705 7.4596 15.8216 7.65624 16.1188 8.02724C16.4166 8.40206 16.5348 8.92515 16.4494 9.50169Z"
          fill="#253B80"
        />
        <path
          d="M29.5825 5.44531H27.6185C27.4311 5.44531 27.2551 5.54459 27.1489 5.71068L24.4401 9.9654L23.2919 5.87677C23.2197 5.62095 22.9983 5.44531 22.7477 5.44531H20.8177C20.5832 5.44531 20.4202 5.68968 20.4948 5.92513L22.6581 12.6948L20.6243 15.7563C20.4644 15.9975 20.6255 16.329 20.9018 16.329H22.8634C23.0496 16.329 23.2239 16.2323 23.3295 16.0694L29.8618 6.01486C30.0182 5.77431 29.8576 5.44531 29.5825 5.44531Z"
          fill="#253B80"
        />
        <path
          d="M36.0818 1.41992H31.9999C31.7212 1.41992 31.4837 1.63629 31.4401 1.93029L29.7894 13.0902C29.7566 13.3104 29.9166 13.5089 30.1248 13.5089H32.2195C32.4141 13.5089 32.5806 13.3575 32.611 13.1513L33.0795 9.98792C33.1224 9.69328 33.3605 9.47692 33.6392 9.47692H34.9307C37.6197 9.47692 39.1707 8.08965 39.5765 5.34056C39.7597 4.13783 39.5837 3.19283 39.0556 2.53101C38.4761 1.80429 37.4478 1.41992 36.0818 1.41992ZM36.5527 5.49583C36.3301 7.05747 35.2111 7.05747 34.1286 7.05747H33.5133L33.946 4.14101C33.9716 3.96474 34.1137 3.83492 34.2814 3.83492H34.5636C35.3001 3.83492 35.9959 3.83492 36.3552 4.28292C36.5694 4.55019 36.6344 4.94729 36.5527 5.49583Z"
          fill="#179BD7"
        />
        <path
          d="M48.2844 5.44551H46.3312C46.1635 5.44551 46.0209 5.57533 45.9958 5.7516L45.9093 6.33451L45.772 6.12324C45.3489 5.46842 44.406 5.24951 43.4643 5.24951C41.3046 5.24951 39.4605 6.99378 39.1013 9.4406C38.9151 10.6611 39.1794 11.8282 39.8287 12.6421C40.4255 13.3905 41.2765 13.7023 42.2904 13.7023C44.0306 13.7023 44.9956 12.5091 44.9956 12.5091L44.9085 13.0882C44.8757 13.3097 45.0356 13.5082 45.2451 13.5082H47.0049C47.2836 13.5082 47.5212 13.2919 47.5647 12.9972L48.6216 5.86424C48.6538 5.64469 48.4939 5.44551 48.2844 5.44551ZM45.5602 9.50169C45.3728 10.6923 44.4854 11.4916 43.3551 11.4916C42.7887 11.4916 42.334 11.2975 42.0428 10.9297C41.7539 10.5644 41.6453 10.0445 41.736 9.46542C41.9133 8.28497 42.8132 7.4596 43.9262 7.4596C44.4812 7.4596 44.9324 7.65624 45.2295 8.02724C45.5285 8.40206 45.6467 8.92515 45.5602 9.50169Z"
          fill="#179BD7"
        />
        <path
          d="M50.5857 1.72567L48.9105 13.0899C48.8777 13.31 49.0377 13.5086 49.2459 13.5086H50.93C51.2099 13.5086 51.4474 13.2922 51.4904 12.9976L53.1423 1.83831C53.1751 1.61813 53.0151 1.41895 52.8069 1.41895H50.9211C50.7546 1.41958 50.6113 1.5494 50.5857 1.72567Z"
          fill="#179BD7"
        />
      </g>
      <defs>
        <clipPath id="clip0_4046_55458">
          <rect
            width="53"
            height="16"
            fill="white"
            transform="translate(0.394531 0.625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PayPalIcon;
